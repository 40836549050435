import logo from './logo.svg';
import './App.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsexports from './aws-exports';
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
import { Loader, ThemeProvider } from '@aws-amplify/ui-react';
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';

Amplify.configure(awsexports);


export function App() {

  const queryParams = new URLSearchParams(window.location.search)
  const userCode = queryParams.get("uc")
  console.log("userCode is:", userCode);
  const [loading, setLoading] = React.useState(true);
  const [createLivenessApiData, setCreateLivenessApiData] = React.useState(null);
  const id = uuidv4();
  const config = {
      headers:{
        "Content-Type": "application/json",
        //'x-api-key': "qJCKDAPdeF9x8SNf9jAqk84xXwXsK3kl9TXZv3UW"
      }
    };
  const body = {
    "request_token":id
  };
  React.useEffect(() => {
    const fetchCreateLiveness = async () => {
      const url = `https://zyr6x5g81c.execute-api.us-east-1.amazonaws.com/v1/liveness/start-liveness-session`;
      let startLivenessSession = await axios.post(url, body, config)
            .then(async resp => {
                console.log("sessionId:", JSON.stringify(resp.data));
                const response = { sessionId: resp.data};
                const data = response;
                setCreateLivenessApiData(data);
                setLoading(false);
                console.log("CreateLivenessApiData:", createLivenessApiData.sessionId)
            })
            .catch(async err => {
                console.log('Error starting liveness session: ', err);
            });
    };
    fetchCreateLiveness();
  }, []);

  const handleAnalysisComplete = async () => {
    const url2 = `https://zyr6x5g81c.execute-api.us-east-1.amazonaws.com/v1/liveness/get-liveness-results`
    const body2 = {
      "session":createLivenessApiData.sessionId
    };
    let getLivenessSessionResults = await axios.post(url2, body2, config)
            .then(async resp => {
                console.log(JSON.stringify(resp.data));
                const confidence = resp.data.Confidence;
                console.log('Sending userCode: ', userCode);
                console.log('Sending livenssScore: ', confidence);
                const url3 = `https://idv24.nyedis.com/answer2`
                const body3 = {
                    "userCode": userCode,
                    "liveness": confidence,
                    "clue": 4,
                    "session": createLivenessApiData.sessionId
                }
                const config2 = {
                  headers:{
                    "Content-Type": "application/json"
                }}
                await axios.post(url3, body3, config2);

                const container = document.getElementById('root');
                const root = ReactDOM.createRoot(container);
                root.render(<h1>Your liveness score: {confidence} </h1>);
            })
            .catch(async err => {
                console.log('Error getting liveness results: ', err);
            });

          window.location.href = 'https://idv24web.nyedis.com/liveness.html';

  };

  return (
    <ThemeProvider>
      {loading ? (
        <Loader />
      ) : (
        <FaceLivenessDetector
          sessionId={createLivenessApiData.sessionId}
          region="us-east-1"
          onAnalysisComplete={handleAnalysisComplete}
        />
      )}
    </ThemeProvider>
  );
}

export default App;